var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-fade-transition',[(_vm.isShow)?_c('div',{staticClass:"white d-flex align-center justify-center",style:({
      position: 'fixed',
      top: '50%',
      left: '50%',
      width: '100vw',
      height: '100vh',
      zIndex: '1000',
      transform: 'translate3d(-50%, -50%, 0)',
    })},[_c('v-progress-circular',{staticClass:"flex-shrink-0",attrs:{"indeterminate":"","size":"110","width":"3","color":"primary"}},[_c('BaseImg',{staticClass:"flex-shrink-0",attrs:{"src":require('@/assets/img/card-blue.svg'),"contain":"","width":"60"}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }